import React, { useCallback, useEffect, useRef, useState } from 'react';
import { reducers } from '../store/configureTourStore';
import withReducers from '../store/withReducers';
import { Row, Col, LabeledInput, Box, LabeledIconInput, Button, AlertNotification } from '@commonsku/styles';
import { PASSWORD_LENGTH, getPasswordStrengthMessages, oauth, passwordStrengthCheck, validateEmail } from '../utils';
import GeneralPageLayout from './LoginPageLayout';

const ResetPasswordApp = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [password2Error, setPassword2Error] = useState('');
    const [changing, setChanging] = useState(false);
    const usernameRef = useRef(null);

    const validateInputs = (username, password, password2) => {
        if (!validateEmail(username)) {
            const errorMessage = 'Invalid username. Should be an email address.';
            setUsernameError(errorMessage);
            return false;
        } else {
            setUsernameError('');
        }

        const passwordErrors = passwordStrengthCheck(password, PASSWORD_LENGTH);

        if (passwordErrors.length > 0) {
            setPasswordError(getPasswordStrengthMessages(passwordErrors).join('. '));
            return false;
        } else {
            setPasswordError('');
        }

        if (password !== password2) {
            const errorMessage = 'Your password confirmation does not match your new password.';
            setPassword2Error(errorMessage);
            return false;
        } else {
            setPassword2Error('');
        }

        return true;
    };

    const resetPassword = useCallback(async (username, password, code) => {
        try {
            setChanging(true);
            await oauth('PUT', 'password/reset', {
                login_name: username,
                new_password: password,
                password_reset_code: code,
            });

            setMessage('Password changed. Redirecting...');
            setTimeout(function() {
				window.location.href = '/logout.php';
			}, 2000);
        } catch (e) {
            const { json } = e;
            setError(json.error || 'Unknown error');
            setChanging(false);
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeInUrl = urlParams.get('code') || '';
        setCode(codeInUrl);
    }, []);

    let content = (
        <>
            <Row>
                <Col xs={12} sm={12}>
                    <Box>
                        <div style={{ background: 'white', height: '48%', minHeight: '535px', padding: '36px', margin: '36px', maxWidth: '600px' }}>
                            <Row>
                                <Col xs={12}>
                                    <h3>Reset your commonsku password</h3>
                                </Col>
                                <Col xs={12}>
                                    <LabeledInput
                                        error={usernameError ? true : false}
                                        disabled={changing}
                                        ref={usernameRef}
                                        label='Username (this is your email address)'
                                        labelOnTop
                                        name='username'
                                        placeholder='you@company.com'
                                        autoComplete='username'
                                        value={username}
                                        onChange={(e) => {
                                            const target = e.target;
                                            setUsername(target.value);
                                        }}
                                    />
                                    {usernameError && <AlertNotification alertType="error">{usernameError}</AlertNotification>}
                                </Col>
                                <Col xs={12} style={{ marginBottom: '1rem' }}>
                                    <LabeledIconInput
                                        error={passwordError ? true : false}
                                        disabled={changing}
                                        Icon={<></>}
                                        iconPosition='right'
                                        iconLabelStyles={{ width: "auto", fontSize: 11, background: 'transparent' }}
                                        labelOnTop
                                        label='New Password'
                                        name="password"
                                        placeholder="Your new password"
                                        password
                                        value={password}
                                        onChange={(e) => {
                                            const target = e.target;
                                            setPassword(target.value);
                                        }}
                                    />
                                    {passwordError && <AlertNotification alertType="error">{passwordError}</AlertNotification>}
                                </Col>
                                <Col xs={12} style={{ marginBottom: '2rem' }}>
                                    <LabeledIconInput
                                        error={password2Error ? true : false}
                                        disabled={changing}
                                        Icon={<></>}
                                        iconPosition='right'
                                        iconLabelStyles={{ width: "auto", fontSize: 11, background: 'transparent' }}
                                        labelOnTop
                                        label='Confirm New Password'
                                        name="password2"
                                        placeholder="Type your new password again"
                                        password
                                        value={password2}
                                        onChange={(e) => {
                                            const target = e.target;
                                            setPassword2(target.value);
                                        }}
                                    />
                                    {password2Error && <AlertNotification alertType="error">{password2Error}</AlertNotification>}
                                </Col>
                                {!!error && <Col xs={12} style={{ marginBottom: '2rem' }}>
                                    <AlertNotification alertType="error">
                                        <div>
                                            <p>{error}</p>
                                        </div>
                                    </AlertNotification>
                                </Col>}
                                {!!message && <Col xs={12} style={{ marginBottom: '2rem' }}>
                                    <AlertNotification alertType="success">
                                        <div>
                                            <p>{message}</p>
                                        </div>
                                    </AlertNotification>
                                </Col>}
                                <Col xs={12}>
                                    <Button disabled={changing} variant="cta" onClick={() => {
                                        if (validateInputs(username, password, password2)) {
                                            resetPassword(username, password, code);
                                        }
                                    }}>Reset Password</Button>
                                </Col>
                            </Row>
                        </div>
                    </Box>
                </Col>
            </Row>
        </>
    );

    return (
        <GeneralPageLayout>
            {content}
        </GeneralPageLayout>
    );
};

export default withReducers(ResetPasswordApp, reducers, true);
